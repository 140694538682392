@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

/* cyrillic-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/KFOmCnqEu92Fr1Mu72xKOzY.woff2') format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/KFOmCnqEu92Fr1Mu5mxKOzY.woff2') format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/KFOmCnqEu92Fr1Mu7GxKOzY.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/KFOmCnqEu92Fr1Mu4mxK.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2') format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2') format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmEU9fBBc4.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2') format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2') format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmWUlfBBc4.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2') format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmYUtfABc4EsA.woff2') format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('./fonts/KFOlCnqEu92Fr1MmYUtfBBc4.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

:root {
	--header-height: 4rem;

	/* ЦВЕТА */

	--first-color: #0a7f0b;
	--text-color: #031c03;

	--first-color-lighten: hsla(212, 11%, 50%, 0.2);
	--title-color: hsl(210, 2%, 18%);

	--container-color: #fff;
	--shadow-color: hsla(106, 23%, 14%, 0.1);

	/* ШРИФТЫ */
	/* .5 rem = 8px; 1rem = 16px */
	--body-font: 'Roboto', sans-serif;
	--big-font-size: 2rem;
	--h1-font-size: 1.5rem;
	--h2-font-size: 1.25rem;
	--h3-font-size: 1rem;
	--normal-font-size: 0.875rem;
	--small-font-size: 0.813rem;
	--smaller-font-size: 0.75rem;

	--font-base: 500;
	--font-medium: 600;
	--font-semi-bold: 700;

	/* ОТСТУПЫ */
	--mb-0-5: 0.5rem;
	--mb-0-75: 0.75rem;
	--mb-1: 1rem;
	--mb-1-5: 1.5rem;
	--mb-2: 2rem;
	--mb-2-5: 2.5rem;

	/* ПОЛОЖЕНИЯ */

	--z-tooltip: 10;
	--z-fixed: 100;

	--max-width: 1440px;
}

/* АДАПТИВНЫЕ РАЗМЕРЫ */

@media screen and (min-width: 1100px) {
	:root {
		--big-font-size: 3.5rem;
		--h1-font-size: 2.25rem;
		--h2-font-size: 1.5rem;
		--h3-font-size: 1.25rem;
		--normal-font-size: 1.125rem;
		--small-font-size: 0.875rem;
		--smaller-font-size: 0.813rem;

		--font-base: 400;
		--font-medium: 500;
		--font-semi-bold: 600;
	}
}

/* ======== ОСНОВНЫЕ ПАРАМЕТРЫ ======== */

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	height: 100%;

	min-width: 260px;

	scroll-behavior: smooth;

	/* overflow-x: hidden; */
}

body,
button,
input,
textarea {
	font-family: var(--body-font);
	font-size: var(--normal-font-size);
	font-weight: var(--font-base);
	line-height: inherit;
	margin: 0;
}

input,
textarea,
submit {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

body {
	/* margin: var(--header-height) 0 0 0; */
	margin: 0;
	padding: 0;
	/* background: linear-gradient(90deg, #f2fcee 0.01%, #b7c7b2 100%); */
	background-image: linear-gradient(
		to right,
		#f2fcee,
		#e3efdf,
		#d4e1d0,
		#c6d4c1,
		#b7c7b2
	);

	color: var(--text-color);
}

.body.body-hidden {
	touch-action: none;
	-webkit-overflow-scrolling: none;

	overflow-y: hidden;

	overscroll-behavior: none;
}

button {
	cursor: pointer;
	border: none;
	outline: none;
}

ul {
	list-style: none;
}

h1,
h2,
h3 {
	color: var(--title-color);
	font-weight: var(--font-semi-bold);
	line-height: initial;
}

h1 {
	font-size: var(--h1-font-size);
}

h2 {
	font-size: var(--h2-font-size);
	list-style: none;
}

h3 {
	font-size: var(--h3-font-size);
}

a {
	text-decoration: none;
	outline: none;
}

p {
	display: block;
	margin: 0;
	padding: 0;
}

img {
	/* max-width: 100%; */
	height: auto;
}
