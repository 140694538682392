.main-wrapper {
	width: 100%;
	height: 100%;

	overflow-x: hidden;

	position: relative;
}

.content-wrapper {
	max-width: var(--max-width);
	margin: 0 auto;
}

.footer__copy {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	white-space: nowrap;

	padding: 8px 8px 8px 8px;
	row-gap: 8px;

	width: 100%;

	font-size: var(--small-font-size);

	column-gap: 12px;
}

.footer__copy a {
	font-size: inherit;
	color: var(--title-color);
	opacity: 1;
	/* font-weight: var(--font-medium); */
}

.footer__copy a:hover {
	color: var(--first-color);
}

.scrolltop {
	position: fixed;
	right: -20%;
	bottom: 20px;

	display: inline-flex;

	justify-content: center;
	align-items: center;

	overflow-y: hidden;
	width: 40px;
	height: 40px;
	border-radius: 50%;

	z-index: var(--z-tooltip);

	background-color: var(--first-color);
	opacity: 0.8;

	transition: 0.4s;

	visibility: hidden;
}

.scrolltop svg {
	width: 24px;
	height: 24px;

	color: #fff;
	fill: #fff;
	z-index: var(--z-tooltip);
}

.scrolltop:hover {
	background-color: var(--text-color);
	opacity: 1;
}

.show-scroll {
	visibility: visible;
	right: 30px;
}

.loader-wrapper {
	width: 100vw;
	height: 100vh;

	background-color: #fff;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;
}

.loading-gif {
	width: 200px;
	height: fit-content;
	font-family: 'Courier New';
}

.loading-gif img {
	width: 100%;
	height: auto;
}

.loading-licence {
	position: fixed;
	bottom: 0;
}
